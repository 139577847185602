import tw from 'tailwind-styled-components';
import { Title, Text } from '../Typography';
import { Picture } from '../../modules/responsivePicture';
import styled from 'styled-components';

export const Container = tw.a`
  w-full
  h-full
  bg-corporate-offWhite
  p-0
  flex
  flex-col
  relative
  rounded-t-2xl
  rounded-b-2xl
  overflow-hidden
  text-darkgray
`;
export const ImageSection = styled.div`
  position: relative;
  overflow: hidden;
  height: 150px;
  width: 100%;
  flex-shrink: 0;
  @media screen and (min-width: 500px) {
    height: 300px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -84px;
    width: 130%;
    left: -15%;
    margin-left: 0%;
    background: #fdfbf9;
    height: 107px;
    border-radius: 100%;
  }
`;
export const ContentSection = tw.div`
  text-center
  relative
  p-4
  flex
  flex-col
  justify-between
  h-full
`;
export const ImageContainer = tw.img`
  w-full
`;
export const SubHeading = tw(Text)`
  mb-4
  mt-1
  text-darkgray
`;
export const Heading = tw(Title)`
  mb-5
`;
export const ButtonWrapper = tw(Text)`
  mx-auto
  h-36
  flex
  justify-center
  items-center
`;
export const Image = tw(Picture)`
  h-72
  mx-auto
  relative
`;
Image.displayName = 'Image';

export const MetaballContainer = tw.div`
  max-h-96
  overflow-hidden
`;
export const MainMetaball = tw.div`
  absolute
  w-full
  h-3/4
  pt-10
`;
export const staticBlob = tw.svg``;
