import React from 'react';
import { Text } from '../Typography';

import { Container, ImageSection, ContentSection, SubHeading, Heading } from './styles';

import { CorpPressStoriesCardType } from './types';
import Picture from '../../modules/responsivePicture/components/Picture';
import { getPictureSources } from '../../modules/getPictureSources';

export const CorpPressStoriesCard = ({
  type,
  cardData,
  className,
  copyDictionary,
}: {
  type: 'story' | 'press';
  copyDictionary: any[];
  cardData?: CorpPressStoriesCardType;
  className?: string;
}) => {
  const getCopy = (key: string) =>
    copyDictionary?.filter((item: any) => item.key === key)[0]?.value;
  return (
    <Container className={className} href={cardData?.slug} target="_self">
      {cardData?.image && (
        <ImageSection>
          <Picture sources={getPictureSources(cardData?.image)} alt="" />
        </ImageSection>
      )}
      <ContentSection>
        <div>
          {cardData?.tag && (
            <Text tag="div" type="lead">
              {getCopy(
                type === 'press' ? `press_tag_${cardData?.tag}` : `story_tag_${cardData?.tag}`,
              )}
            </Text>
          )}
          {cardData?.date && (
            <SubHeading tag="div" type="lead">
              {new Date(cardData?.date).toLocaleDateString('de-de', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </SubHeading>
          )}
          {cardData?.title && (
            <Heading tag="div" type="xs">
              {cardData?.title}
            </Heading>
          )}
        </div>
      </ContentSection>
    </Container>
  );
};
CorpPressStoriesCard.defaultProps = {
  metaBall: false,
};
